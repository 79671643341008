@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

@font-face {
    font-family: font_5by7;
    src: url("App/Static/Fonts/5by7.ttf");
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    position: relative;
}
