.contact {
    padding: 250px 0 0 0;
    margin: 0 auto;
    width: 1000px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.contact .title {
    width: 100%;
    text-align: center;
    font-family: font_5by7;
    font-size: 100px;
    opacity: 0.2;
}

.contact .content {
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    justify-content: center;
}

.contact .content .input {
    margin-bottom: 20px;
    color: white;
}

.contact .content input,
.contact .content textarea,
.contact .content label {
    font-family: "Space Mono", monospace;
}

.contact .content input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.2;
}

.contact .content input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
    opacity: 0.2;
}

.contact .content input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
    opacity: 0.2;
}

.contact .content textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.2;
}

.contact .content textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
    opacity: 0.2;
}

.contact .content textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
    opacity: 0.2;
}

.contact .content .button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.contact .content button {
    font-family: "Space Mono", monospace;
    font-size: 14px;
    text-transform: lowercase;
}

.contact .content button:not(:disabled) {
    border-radius: 0px;
    background-image: linear-gradient(
        45deg,
        hsl(192deg 95% 50%) 0%,
        hsl(193deg 100% 48%) 11%,
        hsl(194deg 100% 47%) 22%,
        hsl(195deg 100% 46%) 33%,
        hsl(197deg 100% 45%) 44%,
        hsl(198deg 100% 44%) 56%,
        hsl(199deg 100% 43%) 67%,
        hsl(200deg 100% 42%) 78%,
        hsl(202deg 100% 41%) 89%,
        hsl(204deg 91% 41%) 100%
    );
    border-radius: 0px;
    margin-top: 10px;
    min-width: 120px;
    transition: filter 250ms;
}

.contact .content button:disabled {
    border-radius: 0px;
    margin-top: 10px;
    min-width: 120px;
}

.contact .content button:hover {
    filter: brightness(90%);
    transition: filter 250ms;
}

.contact .content .reCAPTCHA {
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.contact .content .reCAPTCHA.invisible {
    height: 0;
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
    .contact {
        width: auto;
        padding: 250px 35px 45px 35px;
    }

    .contact .title {
        font-size: 60px;
    }
}
