.footer {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 30px;
    font-family: 'Space Mono', monospace;
    color: white;
}

.footer > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer > div:first-of-type {
    justify-content: flex-start;
}

.footer > div:last-of-type {
    justify-content: flex-end;
}

.footer button,
.footer a {
    opacity: 0.4;
}

.footer button:hover,
.footer a:hover {
    opacity: 1;
}

.footer .copyright-txt {
    opacity: 0.4;
}

.footer .social-icon,
.footer .share-icon,
.footer .arrow-icon {
    color: white;
    font-size: 24px;
}

@media screen and (max-width: 1000px) {
    .footer {
        width: auto;
        padding: 20px;
    }

    .footer > div {
        justify-content: unset;
    }

    .footer > div:first-of-type {
        display: none;
    }
}
