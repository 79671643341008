.landing {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.landing .name-and-role-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: font_5by7;
    width: fit-content;
    margin: 0 auto;
}

.landing .name {
    font-size: 120px;
    opacity: 0.9;
    position: relative;
}

.landing .role {
    font-size: 18px;
    color: black;
    width: 100%;
    text-align: center;
    z-index: 10;
    opacity: 0.6;
    letter-spacing: 79px;
    margin-left: 79px;
    font-family: "Space Mono", monospace;
    margin-top: 10px;
}

.landing .social-container {
    position: absolute;
    top: 0;
    padding: 32px 55px;
    height: calc(100vh - 64px);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.landing .social-container .social-icon {
    color: white;
    opacity: 0.4;
    margin: 6px 0;
}

.landing .social-container .social-icon:hover {
    opacity: 1;
}

.landing .img-parallax {
    background-image: url("../../Static/Images/phil-desforges-KP7p0-DRGbg-unsplash.jpg");
    width: 30%;
    height: 80vh;
    position: absolute;
    left: 200px;
    top: 0;
    background-position: center top;
    background-size: cover;
    opacity: 0.14;
    z-index: 0;
    pointer-events: none;
    filter: grayscale(100%);
}

.landing .welcome-msg {
    position: absolute;
    right: 200px;
    bottom: 150px;
    max-width: 255px;
    z-index: 5;
    text-align: right;
    color: white;
    font-family: "Space Mono", monospace;
}

.landing .welcome-title {
    font-size: 22px;
    opacity: 0.8;
}

.landing .welcome-text {
    opacity: 0.5;
    margin: 28px 0;
    font-family: "Space Mono", monospace;
    line-height: 18px;
}

.landing .welcome-btn {
    display: flex;
    justify-content: flex-end;
}

.landing .welcome-btn button {
    border-radius: 0px;
    background-image: linear-gradient(
        45deg,
        hsl(192deg 95% 50%) 0%,
        hsl(193deg 100% 48%) 11%,
        hsl(194deg 100% 47%) 22%,
        hsl(195deg 100% 46%) 33%,
        hsl(197deg 100% 45%) 44%,
        hsl(198deg 100% 44%) 56%,
        hsl(199deg 100% 43%) 67%,
        hsl(200deg 100% 42%) 78%,
        hsl(202deg 100% 41%) 89%,
        hsl(204deg 91% 41%) 100%
    );
    font-family: "Space Mono", monospace;
    font-size: 14px;
    text-transform: lowercase;
    transition: filter 250ms;
}

.landing .welcome-btn button:hover {
    filter: brightness(90%);
    transition: filter 250ms;
}

.landing .scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 0;
    pointer-events: none;
}

.landing .scroll > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing .scroll .text {
    writing-mode: tb-rl;
    font-family: "Space Mono", monospace;
    color: white;
    opacity: 0.5;
    pointer-events: all;
    font-size: 13px;
}

.landing .scroll .line {
    width: 1px;
    height: 50px;
    background-color: white;
    opacity: 0.4;
    margin-top: 12px;
}

@media screen and (max-width: 1000px) {
    .landing .social-container {
        justify-content: flex-end;
        padding: 35px 20px;
        height: calc(100vh - 70px);
    }

    .landing .social-container .social-icon {
        margin: 0;
        font-size: 24px;
    }

    .landing .name {
        font-size: 48px;
    }

    .landing .role {
        letter-spacing: 26px;
        margin-left: 26px;
        font-size: 15px;
    }

    .landing .welcome-msg {
        display: none;
    }

    .landing .scroll {
        justify-content: flex-end;
    }

    .landing .scroll > div {
        margin-right: 45px;
    }

    .landing .img-parallax {
        right: 0;
        left: unset;
        top: 0;
        width: 60%;
        height: 75vh;
    }
}
