.about {
    padding: 250px 0 0 0;
    width: 1000px;
    margin: 0 auto;
    display: flex;
    position: relative;
    overflow: hidden;
}

.about .img {
    position: absolute;
    top: -40px;
    width: 500px;
    height: 500px;
    background-image: url('../../Static/Images/rupixen-com-zWe0UhHdi60-unsplash.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.15;
    filter: grayscale(100%);
}

.about .about-text-container {
    font-family: 'Space Mono', monospace;
    padding: 100px 265px;
    font-size: 30px;
    color: white;
    opacity: 0.9;
    position: relative;
    transform: translateZ(0);
    line-height: 32px;
}

.about .about-text-container > div {
    margin-bottom: 30px;
}

.about .about-text-container > div:last-of-type {
    margin: 0;
}

@media screen and (max-width: 1000px) {
    .about {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .about .img {
        width: 350px;
        height: 500px;
        right: 20px;
        background-position: left;
    }

    .about .about-text-container {
        padding: 65px 35px 45px 35px;
        font-size: 24px;
    }
}
