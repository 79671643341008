.skills {
    padding: 250px 0 0 0;
    overflow: hidden;
}

.skills .title {
    width: 100%;
    text-align: center;
    font-family: font_5by7;
    font-size: 100px;
    opacity: 0.2;
}

.skills .content {
    width: 1000px;
    margin: 0 auto;
}

.skills .skill-category-container {
    margin-top: 200px;
    transform: translateZ(0);
}

.skills .parallax:last-of-type {
    margin-right: 117px;
}

.skills .skill-category-container.right-align {
    text-align: right;
}

.skills .skill-category-list.right-flex {
    justify-content: flex-end;
}

.skills .skill-category-container .skill-category-title {
    font-size: 130px;
    font-family: font_5by7;
    color: white;
    text-transform: lowercase;
    opacity: 0.8;
}

.skills .skill-category-list {
    font-family: 'Space Mono', monospace;
    font-weight: bold;
    font-size: 26px;
    opacity: 0.6;
    display: flex;
    margin-top: 14px;
    text-transform: lowercase;
}

.skills .skill-category-list .skill-element {
    min-width: fit-content;
}

.skills .skill-category-list.right-flex .skill-element {
    margin-left: 25px;
}

.skills .skill-category-list.left-flex .skill-element {
    margin-right: 25px;
}

.skills .skill-category-list .skill-element:first-of-type {
    margin-left: 0;
}

@media screen and (max-width: 1000px) {
    .skills .title {
        font-size: 60px;
    }

    .skills .content {
        width: auto;
        padding: 45px 35px;
    }

    .skills .skill-category-container {
        margin-top: 80px;
    }

    .skills .skill-category-container .skill-category-title {
        font-size: 60px;
    }

    .skills .skill-category-list {
        display: block;
        margin-top: 10px;
        font-size: 26px;
    }

    .skills .and-more {
        font-size: 18px;
    }

    .skills .parallax:last-of-type {
        margin-right: 0;
    }

    .skills .skill-category-list.right-flex .skill-element {
        margin-left: 0;
        margin-top: 2px;
    }

    .skills .skill-category-list.left-flex .skill-element {
        margin-right: 0;
        margin-top: 5px;
    }
}
