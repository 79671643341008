.app {
    background: hsla(0, 0%, 7%, 1);
    background: linear-gradient(90deg, hsla(0, 0%, 7%, 1) 0%, hsla(0, 0%, 22%, 1) 50%, hsla(0, 0%, 15%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(0, 0%, 7%, 1) 0%, hsla(0, 0%, 22%, 1) 50%, hsla(0, 0%, 15%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(0, 0%, 7%, 1) 0%, hsla(0, 0%, 22%, 1) 50%, hsla(0, 0%, 15%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#131313", endColorstr="#383838", GradientType=1);
}

.app .bg-img {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background-image: url('./Static/Images/henry-co-mjPs0heWsek-unsplash.jpg');
    background-size: cover;
    opacity: 0.14;
    z-index: 0;
    pointer-events: none;
}
