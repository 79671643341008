.navigation {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 50px);
    top: 0;
    right: 0;
    z-index: 5;
    padding: 25px;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.navigation .title {
    color: white;
    font-family: font_5by7;
    font-size: 29px;
    width: 52px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    cursor: pointer;
}

.navigation .nav-items button {
    margin: 0 26px;
    padding: 10px 20px;
    color: white;
    opacity: 0.7;
    border-radius: 0px;
    font-family: 'Space Mono', monospace;
    font-size: 14px;
    text-transform: lowercase;
}

.navigation .nav-items button:hover {
    background-color: rgba(0, 0, 0, 0.04);
    opacity: 1;
}

.navigation .menu-icon-container button {
    color: white;
    opacity: 0.7;
}

.navigation .menu-icon-container {
    display: none;
}

.drawer-content {
    height: 100vh;
    position: relative;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.35);
}

.drawer-content button {
    font-family: 'Space Mono', monospace;
}

.drawer-content .rect {
    position: absolute;
    top: 15px;
    left: calc(50% - 50px);
    width: 100px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.3);
}

.drawer-content .close-icon-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.drawer-content .close-icon-container button {
    color: white;
}

.drawer-content .nav-items-in-drawer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.drawer-content .nav-items-in-drawer button {
    color: white;
    opacity: 0.7;
    padding: 20px 0;
    font-size: 30px;
    font-weight: 300;
}

@media screen and (max-width: 1000px) {
    .navigation {
        padding: 15px 25px;
    }

    .navigation .menu-icon-container {
        display: block;
    }

    .navigation .nav-items {
        display: none;
    }

    .navigation .title {
        margin-left: 0;
    }
}
